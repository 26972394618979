import { CreateHTML } from "../../innerHTML";

import './Description.css';

function Description({dataStr}) {
    return (
        <section className="Description-container">
            <div className="title"><h2>{dataStr[0]}</h2></div>
            <div className="text">
                <CreateHTML str={dataStr[1]}/>
            </div>
        </section>
    )
}

export default Description;