import { useState, useEffect } from "react";
import Form  from "../../Components/Form/Form";
import Modal from "../../Components/Modal/Modal";
import Success from "../../Components/Success/Success";
import { data } from "../../data";
import './Contacts.css';
import './Media.css';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Contacts() {
    const [isSend, setSend] = useState(false);

    const { hash } = useLocation();
    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [hash]);
    return (
        <>
            {
                (isSend !== false) ?
                    <Modal isShowing={isSend} including={
                        {component: <Success type={(isSend === -1) ? "no" : 0}/>}
                    } closeModal={setSend}/>
                    :
                    ""
            }

            <div className="Contacts">
                <Helmet><title>{data.pages.contacts}</title></Helmet>
                <section className="location">
                    <div className="title">
                        <h2>{data.contacts.titleMap}</h2>
                    </div>
                    <div className="info">
                        <div className="map" style={{
                            position: 'relative',
                            overflow: 'hidden'
                        }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d561.0129146622112!2d37.62539530521654!3d55.77497464050093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54a6dc9d95555%3A0x313279c5b72d33b1!2z0KDQldCX0JXQoNCS0KDQo9Ch0JrQntCc!5e0!3m2!1sru!2sru!4v1707848863414!5m2!1sru!2sru" title="googleMap" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                        </div>
                        <address>
                            <div className="how-to-find">
                                <p>{data.contacts.index}</p>
                                <p>{data.contacts.address}</p>
                                <p className="description">{data.contacts.description}</p>
                            </div>
                            <div className="how-to-call">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td>{data.contacts.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Телефон:</td>
                                        <td>{data.contacts.tel}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </address>
                    </div>
                </section>
                <section className="contacts-form" id="messageform">
                    <div className="title"><h2>{data.contacts.titleForm}</h2></div>
                    <div className="form-container">
                        <Form
                            messageLabel={data.form.message.labelContacts}
                            url={
                                data.backend.server+
                                data.backend.path.sendQuestions
                            }
                            setSend={setSend}
                        />
                    </div>
                </section>
            </div>
        </>

    )
}

export default Contacts;