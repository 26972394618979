import { Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Helmet } from "react-helmet";

import './Root.css';

function Root() {
  return (
    <div className="Root">
      <Helmet>
        <title>АНО «СТОЛИЧНЫЙ ЦЕНТР ЮРИДИЧЕСКОЙ ДИАГНОСТИКИ И СУДЕБНОЙ ЭКСПЕРТИЗЫ»</title>
      </Helmet>
      <div className="header-w"><Header/></div>
      <div className="outlet-w"><Outlet/></div>
      <div className="footer-w"><Footer/></div>
    </div>
  );
}

export default Root;
