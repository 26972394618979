import './Navbar.css';
import { NavLink, } from "react-router-dom";
import { data } from "../../../data";


function Navbar() {
    const content = data.navbar.titles.map((v, i) => {
        return <NavLink 
                    to={data.navbar.links[i]} 
                    key={i}
                    className={({isActive}) => isActive ? "active" : ""}
                    style={({isActive}) => {
                        return {color: isActive ? "#219057" : "#000"}
                    }}
                >
                <span>{v}</span>
            </NavLink>
       
    });
    return (
        <nav className="Navbar">
            {content}
        </nav>
    )
    
}

export default Navbar;