import Navbar from "./Navbar/Navbar";
import OuterData from "./OuterData/OuterData";

import './Media.css';

function Header() {
    return (
        <header>
            <OuterData/>
            <Navbar/>
        </header>
    )
}

export default Header;