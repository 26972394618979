import { Link } from "react-router-dom";
import { data } from "../../../data";

function ShowcaseButton() {
  return (
    <div className="ShowcaseButton">
        <Link to={`${data.showcase.buttonLink}#messageform`}>
            <div>ЗАДАЙТЕ ВОПРОС</div>
            <div>Это бесплатно</div>
        </Link>
    </div>
    
  );
}

export default ShowcaseButton;
