import file from '../../../Assets/pustaya_kvitanciya_ano.doc'; // заглушка, нужно будет поменять на pdf
import { data } from '../../../data';

import './Requisites.css';
function Requisites() {
    return (
        <section className='Requisites-container'>
            <div className="title"><h2>{data.requisites.title}</h2></div>
            <div className="requisites">
                <div>
                    <p>
                        {data.requisites.requisitesList.orgtype}
                    </p>
                    <p>
                        {data.requisites.requisitesList.orgname}
                    </p>
                    <p>
                        ОГРН {data.requisites.requisitesList.ogrn}
                    </p>
                    <p>
                        ИНН {data.requisites.requisitesList.inn}
                        &nbsp;КПП {data.requisites.requisitesList.kpp}
                    </p>
                </div>
                <div>
                    <p>
                        р/сч  {data.requisites.requisitesList.rch} 
                        <span className='moc'>{data.requisites.requisitesList.moc}</span>
                    </p>
                    <p>
                        к/сч {data.requisites.requisitesList.kch}
                    </p>
                    <p>БИК {data.requisites.requisitesList.bik}</p>
                </div>
            </div>
            <div className="button"><a href={file} download>
                {data.requisites.button}
            </a></div>
        </section>
    )
}

export default Requisites;