import { data } from "../../../data";
import { Link } from "react-router-dom";
import  logo  from '../../../Assets/logo 2.png';
import './OuterData.css';

// Верхняя часть хедера
function OuterData() {
    return (
        <section className="OuterData">
            <div className="title">
            <Link to="/"><div>
                    <img src={logo} alt="logo"/>
                </div></Link>
                <div className="names">
                    <div>
                    <span><Link to="/">{data.names.orgtype}</Link></span>
                    </div>
                    <div>
                     <h1><Link to="/">{data.names.orgtitle}</Link></h1>
                    </div>
                </div>
            </div>
            <div className="contacts">
                <div>{data.contacts.tel}</div>
                <div >{data.contacts.email}</div>
            </div>
        </section>
    )
}

export default OuterData;