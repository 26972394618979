import { data } from "../../../data";
import { useEffect, useState } from 'react';
import ShowcaseButton from "./ShowcaseButton";
import './Showcase.css';

function Showcase() {
  const list = data.showcase.advantages.map((v,i)=> <li key={v}>{v}</li>)
  const [currentImage, setCurrentImage] = useState(0);
 
  useEffect(()=>{
      const timer = setInterval(()=>{
        if (document.visibilityState !== 'hidden') 
          if (currentImage !== data.showcase.bgs.length-1)
              setCurrentImage((currentImage)=>currentImage+1);
          else
              setCurrentImage(0);
      }, data.showcase.timer);
      return () => clearInterval(timer);
  });
  return (
    <div className="Showcase">
      <div className="bg">
        {data.showcase.bgs.map(
          (v,i)=><img 
                  alt={`Slide ${i}`}
                  className={(currentImage === i) ? "show" : " "}
                  src={v}/>
          )
        }
      </div>
      <div className="wrapper">
        <div className="advantages">
          <ul>
            {list}
          </ul>
        </div>
        <div className="button-side">
          <ShowcaseButton/>
        </div>
      </div>
    </div>
  );
}

export default Showcase;
