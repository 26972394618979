import { data } from '../../../data';



import {HandySvg} from 'handy-svg';
import './List.css';

function List() {
     const parts = [[],[]];
    const count = data.exams.list.length;
    const halfCount = count/2;
    for (let i = 0; i < count; i++) {
        if (i < halfCount) parts[0].push(data.exams.list[i]);
        else parts[1].push(data.exams.list[i]);
    }
    const firstPart = parts[0].map((v,i)=>
        <div className='line' key={i}>
            <div><HandySvg src={v.iconId.default}/></div>
            <div>{v.text}</div>
        </div>
    )
    const secondPart = parts[1].map((v,i)=>
        <div className='line' key={i}>
            <div><HandySvg src={v.iconId.default}/></div>
            <div>{v.text}</div>
        </div>
    )
    return (
        <div className="ExamList-container">
            <div>{firstPart}</div>
            <div>{secondPart}</div>
        </div>
    )
}

export default List;