import { useEffect, useState } from "react";
import { data } from "../../data";
import fetchData from "../../fetch";
import { Helmet } from "react-helmet";
import './News.css'

function News() {
    
    const [newsList, setNewsList] = useState([undefined]);
    const [currentTextIndex, setCurrentTextIndex] = useState(undefined);

    async function createList() {
        const url = `${data.backend.server}${data.backend.path.getNews}`;
        const result = await fetchData('GET', url, false);

        if (result !== undefined) setNewsList(result);
        else setNewsList([undefined]);
        
    }

    function getMonthName(month) {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        return months[month];
    }

    function formatTextWithParagraphs(text) {
        let length = text.length; // ограничение по символам
        let devider;
        if (data.news.symbolLimit > length) devider = length;
        else {
            devider = text.indexOf('\n', data.news.symbolLimit);
            if (devider === -1) devider = data.news.symbolLimit;
        }
        // Разделяем текст на видимую и невидимую часть
        const visibleText = text.slice(0, devider);
        const invisibleText = text.slice(devider+1);

        // Разбиваем текст на абзацы по символу '\n'
        const visibleTextParagraphs = visibleText.split('\n');
        const inVisibleTextParagraphs = invisibleText.split('\n');
        // Создаем новый массив, в котором каждый элемент будет обернут в тег <p>
        const visibleparagraphsHTML = visibleTextParagraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>);
        const inVisibleTextParagraphsHTML = inVisibleTextParagraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>);

        return [visibleparagraphsHTML, inVisibleTextParagraphsHTML];
    }

    function showArticle(index) {
        if (index === currentTextIndex) setCurrentTextIndex(undefined);
        else setCurrentTextIndex(index);
    }
    useEffect(()=>{
        
        createList();
    }, [])
    return (
        <div className="News">
            <Helmet>
                <title>{data.pages.news}</title>
            </Helmet>
            <div className="title"><h2>{data.news.title}</h2></div>
            <div className="news-list">
                {newsList.map((v, i)=>{
                    if (v === undefined) return "";
                    const date = new Date(v.post_date);
                    // Получаем компоненты даты
                    const day = date.getDate(v.post_date);
                    const month = getMonthName(date.getMonth());
                    const year = date.getFullYear();

                    // Собираем статью
                    const paragraphs = formatTextWithParagraphs(v.content);
                    /* FOR TESTS */
                    //const paragraphs = formatTextWithParagraphs(data.news.test); 
                    
                    // Собираем итоговую строку
                    const formattedDate = `${day} ${month}, ${year} год`;

                    return <section className="new-container" key={v.id}>
                        <div className="new-header">
                            <div className="title"><h3>{v.title}</h3></div>
                            <div className="date">{formattedDate}</div>
                        </div>
                        <article>
                            <div>{paragraphs[0]}</div>
                            {
                                (v.content.length > data.news.symbolLimit) ?
                                    <div className={`text ${(currentTextIndex !== i) ? "hidden" : "visible"}`}>{paragraphs[1]}</div>   
                                :
                                ""
                            }
                        </article>
                        {
                            (v.content.length > data.news.symbolLimit && paragraphs[1].length) ?
                                <div className="read-next"><span onClick={()=>showArticle(i)}>Читать далее...</span></div>
                                :
                                ""
                        }
                        
                    </section>
                })  }
            </div>
        </div>
    )
}

export default News;