import axios from "axios";


export default async function fetchData(method, url, body) {
    try {
        if (body) {
            return await axios.post(url, body, {headers: {"Content-Type": "application/json"}})
                .then(response => {
                    if (response.status === 307) {
                        // Получаем новый URL из заголовка Location
                        const newURL = response.headers.location;
                        // Повторяем запрос к новому URL с теми же параметрами, что и в изначальном запросе
                        return axios.post(newURL, body, {headers: {"Content-Type": "application/json"}});
                    }
                    return response.data;
                })
                .then(data=>{
                    return data;
                })
                .catch(error => {
                    throw new Error(error);
                });
        }
        else {
            return await axios.get(url)
                .then(response => {
                    if (response.status === 307) {
                        // Получаем новый URL из заголовка Location
                        const newURL = response.headers.location;
                        // Повторяем запрос к новому URL с теми же параметрами, что и в изначальном запросе
                        return axios.post(newURL, body, {headers: {"Content-Type": "application/json"}});
                    }
                    return response.data;
                })
                .then(data=>{
                    return data;
                })
                .catch(error => {
                    throw new Error(error);
                });
        }
    }catch(error) {
        console.log("Возникла проблема с вашим fetch запросом: ", error.message);
        return undefined;
    }

}

// export default async function fetchData(method, url, body) {
//     const init = (body) ? {
//                     method: method,
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify(body)
//                 } : { method, headers: {} }

//     try {
//         const response = await fetch(url, init);
//         if (!response.ok) {
//             throw new Error("Ответ сети был не ok.");
//         }
//         return await response.json();
//     } catch (error) {
//         console.log("Возникла проблема с вашим fetch запросом: ", error.message);
//         return undefined;
//     }
// }