import { useState } from "react";

import { data } from "../../data";
import yesGif from '../../Assets/galochka.gif';
import yesPng from '../../Assets/галочка.png';
import noPng from '../../Assets/Vector.svg';

import './Success.css';
function Success({type}) {
    const [imgSrc, setImgSrc] = useState(yesGif);

    let top ="";
    let lower = "";
    if (type === "no"){ 
        top = data.success.topNo;
        lower = data.success.lowerNo;
    }
    else if (type === "yesVac") {
        top = data.success.topVac;
        lower = data.success.lowerVac;
    }
    else {
        top = data.success.topCon;
    }


    const handleLoad = () => {
        setTimeout(() => {
            setImgSrc(yesPng); // Устанавливаем пустой src
          }, 1200); // Небольшая задержка для проигрывания GIF-изображения
    }
  

    return (
        <div className="Success">
            <div className="top-text"><h2>{top}</h2></div>
            <div className="icon">
                {
                    (type === "no") ? 
                        <img src={noPng} alt="unsucces" loading="lazy"/>
                        :
                        <img src={imgSrc} alt="succes" onLoad={handleLoad}/>
                }
                
            </div>
            <div className="lower-text">{lower}</div>
        </div>
    )
}

export default Success;