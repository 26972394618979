import { data } from "../../data";

import './Footer.css';

function Footer() {
    return (
        <footer>
            <div>{data.names.orgsmalltitle}</div>
            <div>
                <div>тел.: {data.contacts.tel}</div>
                <div>email.: {data.contacts.email}</div>
            </div>
        </footer>
    )
}

export default Footer;