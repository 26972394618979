import React, {  useEffect, useRef } from 'react';
import { HandySvg } from "handy-svg";
import { data } from "../../../data";

import arrow from "../../../Assets/Main/logos/arrow.svg";

import './Partners.css';
import './Media.css';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  0: { items: 1 },
  1024: { items: 4, itemsFit: 'contain', },
  1440: { items: 7, itemsFit: 'contain', },
};

function Partners() {

  const handleDragStart = (e) => e.preventDefault();
  const carousel = useRef(null);

  const images = data.showcase.partners.map((v)=>v);
  const items = images.map((v, i) => (
    <div
      style={{display: 'flex', justifyContent:'center'}} 
      onDragStart={handleDragStart} className='slide' key={v.id}>
      <img src={v.src} alt={`Slide ${i}`}/>
    </div>
   ))


  useEffect(()=>{
    const list = document.querySelectorAll(".alice-carousel__stage-item");
    for (let i = 0; i < list.length; i++) {
      list[i].style.verticalAlign = "middle";
    }
     
  }, [])
  return (
    <section className="Partners">
      <div className="title">
        <h2>{data.partners.title}</h2>
      </div>
      <div className="slider">
        <div className="arrow">
          <HandySvg 
            src={arrow}
            className="left-arrow"
            onClick={(e) => carousel?.current?.slidePrev(e)}
          />
        </div>
        <AliceCarousel
          key="carousel"
          mouseTracking
          disableDotsControls
          disableButtonsControls
          items={items}
          responsive={responsive}
          ref={carousel}
          infinite={true}
        />
        <div className="arrow">
          <HandySvg 
              src={arrow}
              className="rigt-arrow"
              onClick={(e) => carousel?.current?.slideNext(e)}
          />
        </div>
      </div>  
    </section>
  );
}

export default Partners;
