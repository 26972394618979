import { HandySvg } from "handy-svg";
import cross from '../../Assets/cross-svgrepo-com 1.svg';

import './Modal.css';

function Modal({isShowing, closeModal, including}) {
    const isThereTitle = ('title' in including) ? true : false;
    const content = (isShowing) ?   
                                <>
                                 <div className="modal-backdrop"></div>
                                <div className="Modal-wrapper">
                                    <div className={
                                        (isThereTitle) ? "modal-header" : "modal-header-alone" 
                                    }>
                                        {
                                            (isThereTitle) ? 
                                                <h3>{including.title}</h3>
                                                :
                                                ""
                                        }
                                        <div className="close-button" onClick={()=>closeModal(false)}><HandySvg src={cross}/></div>
                                    </div>
                                    <div className="content">
                                        {including.component}
                                    </div>
                                </div>
                                </>
                               
                                :
                                null
    return (
       
        <>
            {content}
        </>
    )
}

export default Modal;