import { data } from "../../../../data";
import './List.css';
function List() {
    const parts = [[],[]];
    const count = data.courts.сourts.length;
    const halfCount = count/2;
    for (let i = 0; i < count; i++) {
        if (i <= halfCount) parts[0].push(data.courts.сourts[i]);
        else parts[1].push(data.courts.сourts[i]);
    }
    const firstPart = parts[0].map((v,i)=>
        <p key={v}>{v}</p>
    )
    const secondPart = parts[1].map((v,i)=>
        <p key={v}>{v}</p>
    )
    return (
        <div className="List-container">
            <div>{firstPart}</div>
            <div>{secondPart}</div>
        </div>
    )
}

export default List;