import { Link } from "react-router-dom";
import './404.css';
import { Helmet } from "react-helmet";
function NotFound() {
    return (
        <div className="NotFound-container">
            <Helmet><title>404</title></Helmet>
            <div className="text-wrapper">
                <p className="title"><h1>404</h1></p>
                <p className="post-title">ЭТОЙ СТРАНИЦЫ НЕ СУЩЕСТВУЕТ!</p>
                <p className="description">Возможно, эта страница была удалена, или введен не правильный адрес в адресной строке.</p>
            </div>
            <div className="button-wrapper">
                <div className="button-container"><Link to={'/'}>НА ГЛАВНУЮ</Link></div>  
            </div>
        </div>
    )
}

export default NotFound;