import { useRef, useEffect, useState } from 'react';
import './Map.css';

function Hint({mapId, coordinates, onMouseLeave}) {
    const [blockHeight, setBlockHeight] = useState(0);
    const blockRef = useRef(null);

    
    const courts = mapId.map((v, i)=> {
        return <section 
            className={ (i !==0 ) ? "bordered" : ""} 
            key={i}
        >
            <h3>{v.title}</h3>
            <p>{v.address}</p>
            <p>{v.pIndex}</p>
            <p className='tel'>{v.tel}</p>
        </section>
    });
    useEffect(() => {
        if (blockRef.current) {
          const height = blockRef.current.clientHeight;
          setBlockHeight(height);
        }
      }, [coordinates]);
    return (
        <div ref={blockRef} onMouseLeave={onMouseLeave} className='Hint-container' style={{
            left: coordinates[0]+5,
            top: coordinates[1]-blockHeight-5
        }}>
            {courts}
        </div>
    )
}

export default Hint;