export const data = {
    pages: {
        main: 'Главная',
        about: 'О нас',
        exams: 'Экспертизы',
        news: 'Новости',
        vacs: 'Вакансии',
        contacts: 'Контакты'
    },
    backend: {
        server: '/api/',
        port: '55',
        path: {
            getNews: 'get-news',
            getVacancies: 'get-vacancies',
            sendQuestions: 'send-question',
            sendResponse: 'send-response'
        }
    },
    names: {
        orgtype: "Автономная некоммерческая организация",
        orgtitle: "“СТОЛИЧНЫЙ ЦЕНТР ЮРИДИЧЕСКОЙ ДИАГНОСТИКИ И СУДЕБНОЙ ЭКСПЕРТИЗЫ”",
        orgsmalltitle: "© 2024 АНО «СТОЛИЧНЫЙ ЦЕНТР ЮРИДИЧЕСКОЙ ДИАГНОСТИКИ И СУДЕБНОЙ ЭКСПЕРТИЗЫ»"
    },
    contacts: {
        tel: "+7 (495) 797 06 39",
        email: "stolexpertcentr@bk.ru",
        index: 129090,
        address: "г. Москва, ул. Мещанская, д. 7 строение 1, офис № 2",
        description: "Мы находимся в 500м от метро Сухаревская и в 700м от метро Цветной бульвар",
        titleMap: "КАК НАС НАЙТИ",
        titleForm: "ЕСЛИ У ВАС ПОЯВИЛИСЬ ВОПРОСЫ, ОСТАВЬТЕ СВОЙ КОНТАКТНЫЙ НОМЕР ИЛИ E-MAIL В ФОРМЕ, ПРИВЕДЕННОЙ НИЖЕ, И МЫ СВЯЖЕМСЯ С ВАМИ",
    },
    navbar: {
        titles: ["Главная", "О нас", "Экспертизы", "Новости", "Вакансии", "Контакты"],
        links: ['/', '/about', '/examination', '/news', '/vacancies', '/contacts']
    },
    showcase: {
        advantages: [
            "Ораганизация рекомендована судебным департаментом при Верховном суде РФ (УСД-5/283 от 05.02.2014) для использования в работе судов",
            "Более 5000 экспертиз и исследований", "84 эксперта со стажем от 5 до 40 лет",
            "Прогрессивные методы и нестандартный подход"
        ],
        buttonLink: "/contacts",
        timer: 10000,
        partners: [
            { id: 0, src: require('./Assets/Main/logos/cl (1).png') },
            { id: 1, src: require('./Assets/Main/logos/cl (2).png') },
            { id: 2, src: require('./Assets/Main/logos/cl (3).png') },
            { id: 3, src: require('./Assets/Main/logos/cl (4).png') },
            { id: 4, src: require('./Assets/Main/logos/cl (5).png') },
            { id: 5, src: require('./Assets/Main/logos/cl (6).png') },
            { id: 6, src: require('./Assets/Main/logos/cl (7).png') },
            { id: 7, src: require('./Assets/Main/logos/cl (8).png') },
            { id: 8, src: require('./Assets/Main/logos/cl (9).png') },
            { id: 9, src: require('./Assets/Main/logos/cl (10).png') },
            { id: 10, src: require('./Assets/Main/logos/cl (11).png') }
        ],
        bgs: [
            require('./Assets/Main/backgrounds/1.jpg'),
            require('./Assets/Main/backgrounds/2.jpg'),
            require('./Assets/Main/backgrounds/3.jpg'),
            require('./Assets/Main/backgrounds/4.jpg')
        ]
    },
    partners: {
        title: "Мы проводили экспертизы для:",

    },
    about: {
        description: {
            title: "О нашей компании",
            text: `
                <p>АНО «Столичный Центр Юридической Диагностики и Судебной Экспертизы» создано в 2011 году с целью оказания профессиональной практической, консультативной и организационно-методической помощи в производстве любых видов экспертиз.</p>
            <p>Экспертный анализ материалов уголовных, гражданских и арбитражных дел, экспертных заключений и выводов, обобщение научных и практических данных, привело к идеи создания независимой экспертной организации, в которой будет объединена <span>«экспертная элита»</span> и применяться прогрессивные методики исследований, в том числе из международной практики.</p>
            <p>Большой стаж экспертной работы, регулярное повышение квалификации, креативное мышление, позволяют нашим экспертам содействовать в защите законных интересов граждан и организаций, справедливом разрешении споров и конфликтов, создании благоприятных условий реализации права.</p>
            `
        }
    },
    courts: {
        title: "СУДЫ, ДЛЯ КОТОРЫХ МЫ ПРОВОДИЛИ ЭКСПЕРТИЗЫ:",
        showTypes: ["Карта", "Список"],
        сourts: [
            "Девятый арбитражный апелляционный суд",
            "Десятый арбитражный апелляционный суд",
            "Четырнадцатый арбитражный апелляционный суд",
            "Арбитражный суд Волгоградской области",
            "Арбитражный суд города Москвы",
            "Арбитражный суд города Санкт-Петербурга и Ленинградской области",
            "Арбитражный суд Забайкальского края",
            "Арбитражный суд Калининградской области",
            "Арбитражный суд Калужской области",
            "Арбитражный суд Кировской области",
            "Арбитражный суд Краснодарского края",
            "Арбитражный суд Московской области",
            "Арбитражный суд Мурманской области",
            "Арбитражный суд Орловской области",
            "Арбитражный суд Приморского края",
            "Арбитражный суд Республики Крым",
            "Арбитражный суд Рязанской области",
            "Арбитражный суд Самарской области",
            "Арбитражный суд Саратовской области",
            "Арбитражный суд Сахалинской области",
            "Арбитражный суд Тюменской области",
            "Арбитражный суд Хабаровского края",
            "Бабушкинский районный суд города Москвы",
            "Балашихинский городской суд Московской области",
            "Бутырский районный суд города Москвы",
            "Ворошиловский районный суд г. Волгограда",
            "Воскресенский городской суд Московской области",
            "Гагаринский районный суд города Москвы",
            "Головинский районный суд города Москвы",
            "Домодедовский городской суд Московской области",
            "Егорьевский городской суд Московской области",
            "Зеленоградский районный суд города Москвы",
            "Зюзинский районный суд города Москвы",
            "Измайловский районный суд города Москвы",
            "Калужский районный суд Калужской области",
            "Красногорский городской суд Московской области",
            "Кузьминский районный суд города Москвы",
            "Кунцевский районный суд города Москвы",
            "Лефортовский районный суд города Москвы",
            "Люберецкий городской суд Московской области",
            "Люблинский районный суд города Москвы",
            "Московский городской суд",
            "Нагатинский районный суд города Москвы",
            "Наро-фоминский городской суд Московской области",
            "Никулинский районный суд города Москвы",
            "Преображенский районный суд города Москвы",
            "Савеловский районный суд города Москвы",
            "Свердловский районный суд города Костромы",
            "Советский районный суд города Владикавказ РСО-Алания",
            "Ступинский городской суд Московской области",
            "Судебный участок мирового судьи № 176 района \"Митино\"",
            "Судебный участок № 272 района \"Лефортово\"",
            "Судебный участок № 36 района \"Братеево\"",
            "Судебный участок № 366 района \"Хамовники\"",
            "Судебный участок № 63 района \"Ясенево\"",
            "Тверской районный суд города Москвы",
            "Тракторозаводский районный суд города Волгограда",
            "Тушинский районный суд города Москвы",
            "Хамовнический районный суд города Москвы",
            "Хорошевский районный суд города Москвы",
            "Чертановский районный суд города Москвы",
            "Арбитражный суд Амурской области",
            "Арбитражный суд Иркутской области",
            "Арбитражный суд Новосибирской области",
            "Арбитражный суд Томской области",
            "Арбитражный суд Владимирской области",
            "Арбитражный суд Республики Татарстан",
            "Арбитражный суд Белгородской области",
            "Арбитражный суд Воронежской области",
            "Арбитражный суд Ивановской области",
            "Арбитражный суд Курской области",
            "Арбитражный суд Магаданской области",
            "Арбитражный суд Омской области",
            "Арбитражный суд Ростовской области",
            "Арбитражный суд Ярославской области",
            "Арбитражный суд Челябинской области",
            "Арбитражный суд Тульской области"
        ],
        regions: {
            map4: [
                { title: "Девятый арбитражный апелляционный суд", address: "г. Москва, пр. Соломенной сторожки, д. 12", pIndex: "127994", tel: "7 (495) 987-28-00" },
                { title: "Десятый арбитражный апелляционный суд", address: "г. Москва, ул. Садовническая, д. 68/70, стр. 1", pIndex: "117997", tel: "7 (495) 987-27-22, 7 (495) 987-27-64" },
                { title: "Арбитражный суд города Москвы", address: "г. Москва, ул. Большая Тульская, д. 17", pIndex: "115191", tel: "7 (495) 600-96-96" },
                { title: "Бабушкинский районный суд города Москвы", address: "г. Москва, ул. Летчика Бабушкина, д. 39А", pIndex: "129281", tel: "7 (495) 471-04-88" },
                { title: "Бутырский районный суд города Москвы", address: "г. Москва, ул. Образцова, д. 26", pIndex: "127018", tel: "7 (495) 482-52-81" },
                { title: "Гагаринский районный суд города Москвы", address: "г. Москва, ул. Донская, д. 11", pIndex: "119049", tel: "7 (499) 236-49-21" },
                { title: "Головинский районный суд города Москвы", address: "г. Москва, ул. Зои и Александра Космодемьянских, д. 31, корпус 2", pIndex: "125130", tel: "7 (495) 450-28-14" },
                { title: "Зеленоградский районный суд города Москвы", address: "г. Зеленоград, корп. 2001", pIndex: "124365", tel: "7 (499) 729-94-35" },
                { title: "Зюзинский районный суд города Москвы", address: "г. Москва. ул. Кржижанского, д. 20/30, к. 4", pIndex: "117218", tel: "7 (499) 125-39-26" },
                { title: "Измайловский районный суд города Москвы", address: "г. Москва, ул. Первомайская, д 12", pIndex: "105037", tel: "7 (499) 261-01-02" },
                { title: "Кунцевский районный суд города Москвы", address: "г. Москва, ул. Ярцевская, д. 12", pIndex: "121351", tel: "7 (495) 416-62-15" },
                { title: "Лефортовский районный суд города Москвы", address: "г. Москва, Андроньевская пл., д. 5/9", pIndex: "105120", tel: "7 (495) 912-78-86" },
                { title: "Люблинский районный судгорода Москвы", address: "г. Москва, ул. Марьинский парк, д. 29", pIndex: "109559", tel: "7 (499) 784-76-20" },
                { title: "Московский городской суд", address: "г. Москва, ул. Богородский вал. д. 8", pIndex: "107996", tel: "8 (495) 533-83-34" },
                { title: "Нагатинский районный суд города Москвы", address: "г. Москва, Каширский пр., д. 3", pIndex: "115201", tel: "7 (499) 530-58-06" },
                { title: "Никулинский районный сул города Москвы", address: "г. Москва, Мичуринский проспект, д. 17, к. 1", pIndex: "119192", tel: "7 (499) 739-31-51" },
                { title: "Преображенский районный суд города Москвы", address: "г. Москва, ул. 2-я Бухвостова, вл. 4", pIndex: "107076", tel: "7 (499) 530-58-06" },
                { title: "Савеловский районный суд города Москвы", address: "г. Москва, ул. Бутырский вал, д. 7. стр. 1", pIndex: "125047", tel: "7 (499) 250-40-31" },
                { title: "Судебный участок мирового судьи № 176 районв \"Митино\"", address: "г. Москва, ул. Генерала Белоборода, д. 37", pIndex: "125222", tel: "8 (495) 505 97 49" },
                { title: "Судебный учсток № 272 района \"Лефортово\"", address: "г. Москва, Перовский пр., д. 7", pIndex: "111024", tel: "7 (495) 361-70-36" },
                { title: "Судебный участок № 36 района \"Братеево\"", address: "г. Москва, ул. Домодеевская, д. 24, к. 5", pIndex: "115408", tel: "7 (495) 340-65-13" },
                { title: "Судебный участок № 366 района \"Хамовники\"", address: "г. Москва, Космодомианская наб., д. 26/55, с . 7", pIndex: "115035", tel: "7 (495) 953-53-37" },
                { title: "Судебный участок № 63 района \"Ясенево\"", address: "г. Москва, пр. Одоеавского, д. 11, к. 7", pIndex: "117574", tel: "7 (495) 427-94-54" },
                { title: "Тверской районный суд города Москвы", address: "г. Москва, ул. Каланчевская, д 43А", pIndex: "129090", tel: "7 (499) 530-58-06" },
                { title: "Тушинский районный суд города Москвы", address: "г. Москва, ул. Героев Панфиловсцев, д. 26, к. 1", pIndex: "125373", tel: "7 (499) 530-58-06" },
                { title: "Хамовнический районный суд города Москвы", address: "г. Москва, ул. 7-о1 Ростовский пер., д. 21", pIndex: "119121", tel: "7 (499) 248-63-21" },
                { title: "Хорошевский районный суд города Москвы", address: "г. Москва, ул. Маршала Тухачевского, д. 25, к. 1", pIndex: "123154", tel: "7 (499) 530-58-06" },
                { title: "Чертановский районный суд города Москвы", address: "г. Москва, ул. Артековская, д. 3А", pIndex: "117556", tel: "7 (499) 530-58-06" }
            ],
            map8: [
                { title: "Арбитражный суд Волгоградской области", address: "г. Волгоград, улица 7-й Гвардейской, д. 2", pIndex: "400005", tel: "7 (8442) 23-00-78" },
                { title: "Ворошиловский районный суд г. Волгограда", address: "г. Волгоград, ул. Рокоссовского, д. 10", pIndex: "400066", tel: "7 (8442) 23-00-78" },
                { title: "Тракторозаводский районный суд города Волгограда", address: "г. Волгоград, ул. Ополченская, д. 4", pIndex: "400006", tel: "7 (8442) 39-45-58" }
            ],
            map13: [
                { title: "Четырнадцатый арбитражный апелляционный суд", address: "г. Вологда ул. Батюшкова, д.12", pIndex: "160001", tel: "7 (8172) 78-09-14" }

            ],
            map18: [
                { title: "Арбитражный суд города Санкт-Петербурга и Ленинградской области", address: "г. Санкт-Петербург, ул. Смольного, д. 6 стр 1", pIndex: "191124", tel: "7 (812) 643-48-18" }
            ],
            map22: [
                { title: "Арбитражный суд Забайкальского края", address: "г. Чита, ул. Выставочная, д. 6", pIndex: "672002", tel: "7 (3022)-33-05-01" }
            ],
            map16: [
                { title: "Арбитражный суд города Санкт-Петербурга и Ленинградской области", address: "г. Санкт-Петербург, ул. Смольного, д. 6 стр 1", pIndex: "191124", tel: "7 (812) 643-48-18" }
            ],
            map21: [
                { title: "Арбитражный суд Калининградской области", address: "г. Калининград, ул. Рокоссовского, д. 2-4", pIndex: "236040", tel: "7 (4012) 57-22-22" }
            ],
            map2: [
                { title: "Арбитражный суд Калужской области", address: "г. Калуга, ул. Ленина, д. 90", pIndex: "248000", tel: "7 (4842) 59-94-57" },
                { title: "Калужский районный суд Калужской области", address: "г. Калуга, ул. Кутузова, д. 4", pIndex: "248600", tel: "7 (4842) 57-57-28" }
            ],
            map15: [
                { title: "Арбитражный суд Кировской области", address: "г. Киров, ул. Карла Либкнехта, д. 102", pIndex: "610017", tel: "7 (8332) 70-91-18" }
            ],
            map6: [
                { title: "Арбитражный суд Краснодарского края", address: "г. Краснодар, ул. Постовая, д. 32", pIndex: "350063", tel: "7 (861) 293-81-03" }
            ],
            map3: [
                { title: "Арбитражный суд Московской области", address: "г. Москва, просп. Академика Сахарова, д. 18", pIndex: "107053", tel: "7 (499) 975-29-46" },
                { title: "Балашихинский районный суд Московской области", address: "г. Балашиха, Московский пр., д.1", pIndex: "143903", tel: "7 (495) 529-33-84" },
                { title: "Воскресенский городской суд Московской области", address: "г. Воскресенск, ул Советская, д.3а", pIndex: "140200", tel: "8 (49644) 2-17-37" },
                { title: "Домодедовский городской суд Московской области", address: "г. Домодедово, ул. Советская, д. 26", pIndex: "142000", tel: "8 (496) 793-28-26" },
                { title: "Егорьевский городской суд Московской области", address: "г. Егорьевск, проспект Ленина, д.12", pIndex: "140300", tel: "8(49640)4-18-76" },
                { title: "Красногорский городской суд города Москвы", address: "г. Красногорск, ул. Первомайская, д.6", pIndex: "143400", tel: "8(495) 562-11-44" },
                { title: "Люберецкий районный суд города Москвы", address: "г. Люберцы, ул. Калараш, д. 19", pIndex: "140005", tel: "7 (495) 503-55-54" },
                { title: "Наро-фомиский городской суд Московской области", address: "г. Наро-Фоминск, ул. Генерала Ефремова, д.5", pIndex: "143300", tel: "8 (49634) 738-83" },
                { title: "Ступинский городской суд Московской области", address: "г. Ступино, ул. Куйбышева, д. 53", pIndex: "142800", tel: "8 (496) 642-04-45" }
            ],
            map19: [
                { title: "Арбитражный суд Мурманской области", address: "г. Мурманск, ул. Книповича, д. 20", pIndex: "183038", tel: "7 (8152) 45-36-18" }
            ],
            map9: [
                { title: "Арбитражный суд Орловской области", address: "г. Орел, ул. М.Горького, д. 42", pIndex: "302000", tel: "7 (4862) 43-24-44" }
            ],
            map20: [
                { title: "Арбитражный суд Приморского края", address: "г. Владивосток, ул. Октябрьская, д. 27", pIndex: "690091", tel: "7 (423) 221-53-76" }
            ],
            map17: [
                { title: "Арбитражный суд Республики Крым", address: "г. Симферополь, ул. Александра Невского, д. 29/11", pIndex: "295000", tel: "7 (3652) 77-38-87" }
            ],
            map10: [
                { title: "Арбитражный суд Рязанской области", address: "г. Рязань, ул. Почтовая, д. 43/44", pIndex: "390000", tel: "7 (4912) 20-95-00" }
            ],
            map12: [
                { title: "Арбитражный суд Самарской области", address: "г. Самара, ул. Самарская, д. 203Б", pIndex: "443001", tel: "7 (846) 207-55-15" }
            ],
            map11: [
                { title: "Арбитражный суд Саратовской области", address: "г. Саратов, ул.Бабушкин Взвоз, д.1", pIndex: "410002", tel: "7 (8452) 98-39-39" }
            ],
            map1: [
                { title: "Арбитражный суд Сахалинской области", address: "г. Южно-Сахалинск, пр. Коммунистический, д. 28", pIndex: "693000", tel: "7 (4242) 46-09-45" }
            ],
            map23: [
                { title: "Арбитражный суд Тюменской области", address: "г. Тюмень, ул. Ленина, д. 74", pIndex: "625052", tel: "7 (3452) 25-81-13" }
            ],
            map5: [
                { title: "Арбитражный суд Хабаровского края", address: "г. Хабаровск, ул. Ленина, д. 37", pIndex: "680030", tel: "7 (4212) 91-08-31" }
            ],
            map14: [
                { title: "Свердловский районный суд города Костромы", address: "г. Кострома, ул. Долматова, д. 14", pIndex: "156961", tel: "7 (4942) 31-52-55" }
            ],
            map7: [
                { title: "Советский районный суд города Владикавказ РСО-Алания", address: "г. Владикавказ, ул. Зортова, д. 4", pIndex: "362002", tel: "7 (8672) 76-11-91" }
            ],
            map24: [
                { title: "Арбитражный суд Амурской области", address: "г. Благовещенск, ул. Ленина, д. 164", pIndex: "675023", tel: "7 (4162) 59-59-00" }
            ],
            map25: [
                { title: "Арбитражный суд Иркутской области", address: "г. Иркутск, бул. Гагарина, д. 70", pIndex: "664025", tel: "7 (3952) 254-200" }
            ],
            map26: [
                { title: "Арбитражный суд Новосибирской области", address: "г. Новосибирск, ул. Нижегородская, д. 6", pIndex: "630102", tel: "7 (383) 269-69-90" }
            ],
            map27: [
                { title: "Арбитражный суд Томской области", address: "г. Томск, пр. Кирова, д. 10", pIndex: "634050", tel: "7 (3822) 284-083" }
            ],
            map28: [
                { title: "Арбитражный суд Владимирской области", address: "г. Владимир, Октябрьский просп., д. 19", pIndex: "600005", tel: "7 (4922) 47-23-65" }
            ],
            map29: [
                { title: "Арбитражный суд Республики Татарстан", address: "г. Казань, ул. Ново-Песочная, д. 40", pIndex: "420107", tel: "7 (843) 533-50-00" }
            ],
            map30: [
                { title: "Арбитражный суд Белгородской области", address: "г. Белгород, Народный бульвар, д. 135", pIndex: "308000", tel: "7 (473) 252-53-44" }
            ],
            map31: [
                { title: "Арбитражный суд Воронежской области", address: "г. Воронеж, ул. Средне-Московская, д. 77", pIndex: "394030", tel: "7 (473) 252-53-44" }
            ],
            map32: [
                { title: "Арбитражный суд Ивановской области", address: "г. Иваново, ул. Б. Хмельницкого, д. 59Б", pIndex: "153022", tel: "7 (4932) 42-96-65" }
            ],
            map33: [
                { title: "Арбитражный суд Курской области", address: "г. Курск, ул. К.Маркса, д. 25", pIndex: "305004", tel: "7 (4712) 53-69-36" }
            ],
            map34: [
                { title: "Арбитражный суд Магаданской области", address: "г. Магадан, просп. К.Маркса, д. 62", pIndex: "685000", tel: "7 (4132) 65-03-80" }
            ],
            map35: [
                { title: "Арбитражный суд Омской области", address: "г. Омск, ул. Учебная, д. 51", pIndex: "644024", tel: "7 (3812) 31-56-51" }
            ],
            map36: [
                { title: "Арбитражный суд Ростовской области", address: "г. Ростов-на-Дону, ул. Станиславского, д. 10/11-13", pIndex: "344002", tel: "7 (863) 282-84-44" }
            ],
            map37: [
                { title: "Арбитражный суд Ярославской области", address: "г. Ярославль, просп. Ленина, д. 2", pIndex: "150999", tel: "7 (4852) 28-11-82" }
            ],
            map38: [
                { title: "Арбитражный суд Челябинской области", address: "г. Челябинск, ул. Воровского, д. 2", pIndex: "454091", tel: "7 (351) 225-03-19" }
            ],
            map39: [
                { title: "Арбитражный суд Тульской области", address: "г. Тула, Красноармейский проспект, д. 5", pIndex: "300041", tel: "7 (4872) 250-800" }
            ],
            map40: [],
            map41: [],
            map42: [],
            map43: [],
            map44: [

            ],
            map45: [
                {
                    title: "Арбитражный суд Красноярского края",
                    address: "г. Красноярск, ул. Ленина, д. 1",
                    pIndex: "660049",
                    tel: "7 (391) 226-59-00"
                }
            ],
            map46: [],
            map47: [],
            map48: [],
            map49: [],
            map50: [],
            map51: [],
            map52: [],
            map53: [],
            map54: [],
            map55: [
                // Нижегородская
                {
                    title: "Арбитражный суд Нижегородской области",
                    address: "г. Нижний Новгород, Кремль, корп. 9",
                    pIndex: "603082",
                    tel: "7 (831) 439-10-40"
                }
            ],
            map56: [
                // Башкирия
                {
                    title: "Арбитражный суд Республики Башкортостан",
                    address: "г. Уфа, ул. Гоголя, д.18",
                    pIndex: "450057",
                    tel: "7 (347) 222-92-05"
                  }
            ],
            map57: [
                // курган
                {
                    title: "Арбитражный суд Курганской области",
                    address: "г. Курган, ул. Климова, д. 62",
                    pIndex: "640002",
                    tel: "7 (3522) 46-64-84"
                  }
            ],
            map58: [],
            map59: [],
            map60: [],
            map61: [],
            map62: [],
            map63: [],
            map64: [],
            map65: [],
            map66: [],
            map67: [
                {
                    title: "Арбитражный суд Ямало-Ненецкой автономной области",
                    address: "г. Салехард, ул. Республики, д. 102",
                    pIndex: "629008",
                    tel: "7 (34922) 5-31-00"
                }
            ],
            map68: [
                // Тверь
                {
                    title: "Арбитражный суд Тверской области",
                    address: "г. Тверь, пл. Святого Благоверного Князя Михаила Тверского, д. 5",
                    pIndex: "170100",
                    tel: "7 (4822) 390-255"
                }
            ],
            map69: [],
            map70: [],
            map71: [],
            map72: [],
            map73: [],
            map74: [],
            map75: [

                {
                    title: "Арбитражный суд Алтайского края",
                    address: "г. Барнаул, просп. Ленина, д. 76",
                    pIndex: "656015",
                    tel: "7 (3852) 29-88-01"
                    }
            ],
            map76: [
                // Оренбург
                {
                    title: "Арбитражный суд Оренбургской области",
                    address: "г. Оренбург, ул. Краснознаменная, д. 56",
                    pIndex: "460024",
                    tel: "7 (3532) 45-01-93"
                    }
            ],
            map77: [],
            map78: [
                // Дагестан
                {
                    title: "Арбитражный суд Республики Дагестан",
                    address: "г. Махачкала, ул. Керимова, д. 7",
                    pIndex: "367009",
                    tel: "7 (8722) 69-49-68"
                    }
            ],
            map79: [],
            map80: [],
            map81: [],
            map82: [],
            map83: [],
            map84: [],
            map85: [],
            map86: []

        }
    },
    requisites: {
        title: "НАШИ РЕКВИЗИТЫ:",
        requisitesList: {
            orgtype: "Автономная некоммерческая организация ",
            orgname: "«Столичный центр юридической диагностики и судебной экспертизы»",
            ogrn: "1117799023870 от 29.11.2011г.",
            inn: "7743109427", kpp: "771501001",
            rch: "40703810138000007463 в ПАО «СБЕРБАНК» в ",
            moc: 'г. Москвы',
            kch: "30101810400000000225",
            bik: "044525225"
        },
        button: "Скачать платежку"
    },
    exams: {
        description: {
            title: "ВИДЫ ПРОВОДИМЫХ ЭКСПЕРТИЗ И ИССЛЕДОВАНИЙ",
            text: "Наша организация предоставляет широкий спектр услуг по подготовке и производству судебных экспертиз и экспертных исследований, узкоспециализированных профессиональных консультаций, анализу и рецензированию заключений экспертов по арбитражным, гражданским, уголовным и административным делам."
        },
        list: [
            { iconId: require("./Assets/Expertises/car-svgrepo-com 1.svg"), text: "Автотехническая (в том числе оценка АТС)" },
            { iconId: require("./Assets/Expertises/bullet-free-2-svgrepo-com 1.svg"), text: "Баллистическая" },
            { iconId: require("./Assets/Expertises/accounting-svgrepo-com 1.svg"), text: "Бухгалтерская" },
            { iconId: require("./Assets/Expertises/skull-line-drawing-svgrepo-com 1.svg"), text: "Восстановление лица по черепу" },
            { iconId: require("./Assets/Expertises/finger-print-svgrepo-com (1) 1.svg"), text: "Дактилоскопическая" },
            { iconId: require("./Assets/Expertises/map-svgrepo-com (1) 1.svg"), text: "Землеустроительная" },
            { iconId: require("./Assets/Expertises/head-svgrepo-com 1.svg"), text: "Идентификация личности по черепу и фотоснимкам" },
            { iconId: require("./Assets/Expertises/gear-svgrepo-com 1.svg"), text: "Инженерно-техническая" },
            { iconId: require("./Assets/Expertises/cpu-free-4-svgrepo-com 1.svg"), text: "Компьютерно-техническая" },
            { iconId: require("./Assets/Expertises/letter-a-svgrepo-com 1.svg"), text: "Лингвистическая" },
            { iconId: require("./Assets/Expertises/price-tag-rotate-svgrepo-com 1.svg"), text: "Оценочная" },
            { iconId: require("./Assets/Expertises/fire-svgrepo-com 1.svg"), text: "Пожарно-техническая" },
            { iconId: require("./Assets/Expertises/portrait-photography-svgrepo-com 1.svg"), text: "Портретная" },
            { iconId: require("./Assets/Expertises/pen-svgrepo-com 1.svg"), text: "Почерковедческая" },
            { iconId: require("./Assets/Expertises/head-svgrepo-com (1) 1.svg"), text: "Психологическая" },
            { iconId: require("./Assets/Expertises/brain-13-svgrepo-com 1.svg"), text: "Психолого-психиатрическая" },
            { iconId: require("./Assets/Expertises/hammer-tool-settings-fix-repair-svgrepo-com 1.svg"), text: "Строительно-техническая" },
            { iconId: require("./Assets/Expertises/health-filled-svgrepo-com 1.svg"), text: "Судебно-медицинское исследование" },
            { iconId: require("./Assets/Expertises/Group.svg"), text: "Технико-криминалистическая" },
            { iconId: require("./Assets/Expertises/document-svgrepo-com 1.svg"), text: "Техническая экспертиза документов (в том числе определение давности)" },
            { iconId: require("./Assets/Expertises/market-purchase-svgrepo-com 1.svg"), text: "Товароведческая" },
            { iconId: require("./Assets/Expertises/footprint-svgrepo-com 1.svg"), text: "Трасологическая" },
            { iconId: require("./Assets/Expertises/physics-svgrepo-com 1.svg"), text: "Физико-химическая" },
            { iconId: require("./Assets/Expertises/money-dollar-cash-funds-svgrepo-com 1.svg"), text: "Финансово-экономическая" },
            { iconId: require("./Assets/Expertises/sound-waveform-wave-svgrepo-com 1.svg"), text: "Фоноскопическая" },
            { iconId: require("./Assets/Expertises/photo-camera-filled-svgrepo-com 1.svg"), text: "Фото и видео техническая" },
            { iconId: require("./Assets/Expertises/chemistry-lab-instrument-svgrepo-com 1.svg"), text: "Химическая" },
            { iconId: require("./Assets/Expertises/knife-svgrepo-com 1.svg"), text: "Холодного оружия" }
        ],
        ending: "И другие виды экспертиз и исследований...",
    },
    news: {
        title: 'Новости',
        symbolLimit: 1128,
        test: [
            {
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper pretium mi. Sed nulla velit, aliquet nec nibh nec, blandit venenatis metus. Ut tempor convallis tortor, vel condimentum dui tempor vel. Donec finibus, augue quis ullamcorper molestie, orci dui suscipit ipsum, pretium condimentum lacus velit ut magna. Proin tempus justo massa, sed ultricies ex dignissim vitae. Morbi lorem odio, volutpat eu condimentum ut, rhoncus vitae ipsum. Donec in venenatis mi. Phasellus arcu justo, fringilla vel viverra tempus, porttitor at arcu. Phasellus velit neque, tristique eu metus ut, tincidunt gravida turpis. Integer ut ipsum non sapien vestibulum fringilla in id nulla.
                \n
                Aliquam fringilla auctor sodales. Curabitur vitae feugiat quam. Cras varius efficitur tristique. Cras rutrum dictum interdum. Phasellus aliquet dolor sed pharetra tristique. Aliquam sit amet orci vitae diam ullamcorper tincidunt. In porta nisl porta nibh porta, id aliquam leo tincidunt. Nulla tempus enim ex, id accumsan nisl sollicitudin eget.
                \n
                Etiam mollis nunc eget neque tempor, eu fermentum augue accumsan. Ut egestas pellentesque egestas. Aliquam at elit posuere, vulputate magna et, ullamcorper nulla. Nullam a risus quis quam malesuada pellentesque. Vestibulum quis tincidunt felis, iaculis dictum enim. Vestibulum viverra at ex vitae congue. Sed in luctus dolor. Nulla quis turpis ut eros mollis rutrum. Pellentesque eleifend, magna sed placerat aliquam, felis nisl maximus enim, eget gravida odio lorem ac magna. Cras auctor viverra efficitur. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec ac sodales sem. Sed eleifend justo at blandit imperdiet. Cras id augue in arcu euismod posuere.`,
                               title: "Новость",
                id: "312312sdasd",
                post_date: "1111111",
            },
            {
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper pretium mi. Sed nulla velit, aliquet nec nibh nec, blandit venenatis metus. Ut tempor convallis tortor, vel condimentum dui tempor vel. Donec finibus, augue quis ullamcorper molestie, orci dui suscipit ipsum, pretium condimentum lacus velit ut magna. Proin tempus justo massa, sed ultricies ex dignissim vitae. Morbi lorem odio, volutpat eu condimentum ut, rhoncus vitae ipsum. Donec in venenatis mi. Phasellus arcu justo, fringilla vel viverra tempus, porttitor at arcu. Phasellus velit neque, tristique eu metus ut, tincidunt gravida turpis. Integer ut ipsum non sapien vestibulum fringilla in id nulla.
                \n
                Aliquam fringilla auctor sodales. Curabitur vitae feugiat quam. Cras varius efficitur tristique. Cras rutrum dictum interdum. Phasellus aliquet dolor sed pharetra tristique. Aliquam sit amet orci vitae diam ullamcorper tincidunt. In porta nisl porta nibh porta, id aliquam leo tincidunt. Nulla tempus enim ex, id accumsan nisl sollicitudin eget.
                \n
                Etiam mollis nunc eget neque tempor, eu fermentum augue accumsan. Ut egestas pellentesque egestas. Aliquam at elit posuere, vulputate magna et, ullamcorper nulla. Nullam a risus quis quam malesuada pellentesque. Vestibulum quis tincidunt felis, iaculis dictum enim. Vestibulum viverra at ex vitae congue. Sed in luctus dolor. Nulla quis turpis ut eros mollis rutrum. Pellentesque eleifend, magna sed placerat aliquam, felis nisl maximus enim, eget gravida odio lorem ac magna. Cras auctor viverra efficitur. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec ac sodales sem. Sed eleifend justo at blandit imperdiet. Cras id augue in arcu euismod posuere.
                \n
                Proin tempor lectus vel tellus pellentesque sagittis. Donec tempus a ante ut feugiat. Aliquam euismod consequat ultrices. Ut ullamcorper enim diam, at egestas mi cursus et. Sed nec lacinia ligula. Donec efficitur leo sed lorem rhoncus, sed mollis magna feugiat. Duis purus elit, interdum nec nulla ut, tempor imperdiet ex. Maecenas eget dignissim erat.
                \n
                Etiam congue, est vel finibus pellentesque, mauris orci interdum felis, eget hendrerit dolor elit eget nisi. Duis orci ex, fermentum eget feugiat in, mattis sit amet quam. Aenean viverra pulvinar lobortis. Mauris vel pellentesque sem. Donec felis tellus, finibus vitae urna et, interdum suscipit lacus. In ut dolor non diam varius faucibus iaculis at ligula. Sed vel tortor euismod, congue lectus et, tincidunt libero. Proin lacinia, eros eget aliquam ornare, tortor elit lobortis diam, et semper lorem nisi non felis. Fusce accumsan at mi vel aliquam. Sed tincidunt aliquam orci eu luctus. Ut lacus velit, efficitur id dolor sit amet, luctus volutpat tortor. Donec nec lectus ultrices, dictum turpis in, vestibulum purus.
                \n
                Sed suscipit, mauris hendrerit eleifend lacinia, quam lacus condimentum sapien, sit amet pulvinar arcu mauris id nunc. Suspendisse vel nisi placerat, ultricies ante ut, feugiat est. Fusce pulvinar in magna sit amet efficitur. Mauris facilisis odio id dolor fringilla, ac tincidunt odio rhoncus. Maecenas mattis semper magna, eget blandit tortor. Mauris vehicula posuere eros, in pharetra massa rutrum in. Nullam dignissim rhoncus felis, in finibus neque egestas ac. Phasellus leo ante, accumsan sit amet nibh et, rutrum sodales neque. Donec ultrices est leo, et tristique felis pharetra nec. Vestibulum malesuada neque vestibulum elit placerat, eget bibendum arcu vehicula. Duis quis eleifend leo. Nam ultricies mollis ipsum at pharetra.
                \n
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla vitae quam odio. Praesent quis ligula dictum, suscipit turpis id, mollis mauris. Nunc quis placerat neque. Donec at metus leo. Fusce eleifend massa in tortor imperdiet volutpat. Aliquam molestie felis turpis, mattis molestie nunc ullamcorper eu. Vivamus augue justo, placerat quis eros lobortis, sollicitudin ornare nisi. Donec viverra cursus mauris a luctus. Duis at quam sed sapien ultricies egestas id non turpis. Pellentesque commodo, lectus ut feugiat congue, libero nunc egestas massa, malesuada posuere felis dolor at massa. Suspendisse vel sollicitudin orci. Aliquam quis porttitor tellus. Praesent posuere, sapien a pretium egestas, elit urna maximus nisl, id suscipit augue lacus vel mauris.`,
                title: "Новость",
                id: "312312sdasd",
                post_date: "1111111",
            },
            {
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper pretium mi. Sed nulla velit, aliquet nec nibh nec, blandit venenatis metus. Ut tempor convallis tortor, vel condimentum dui tempor vel. Donec finibus, augue quis ullamcorper molestie, orci dui suscipit ipsum, pretium condimentum lacus velit ut magna. Proin tempus justo massa, sed ultricies ex dignissim vitae. Morbi lorem odio, volutpat eu condimentum ut, rhoncus vitae ipsum. Donec in venenatis mi. Phasellus arcu justo, fringilla vel viverra tempus, porttitor at arcu. Phasellus velit neque, tristique eu metus ut, tincidunt gravida turpis. Integer ut ipsum non sapien vestibulum fringilla in id nulla.
                \n
                Aliquam fringilla auctor sodales. Curabitur vitae feugiat quam. Cras varius efficitur tristique. Cras rutrum dictum interdum. Phasellus aliquet dolor sed pharetra tristique. Aliquam sit amet orci vitae diam ullamcorper tincidunt. In porta nisl porta nibh porta, id aliquam leo tincidunt. Nulla tempus enim ex, id accumsan nisl sollicitudin eget.
                \n
                Etiam mollis nunc eget neque tempor, eu fermentum augue accumsan. Ut egestas pellentesque egestas. Aliquam at elit posuere, vulputate magna et, ullamcorper nulla. Nullam a risus quis quam malesuada pellentesque. Vestibulum quis tincidunt felis, iaculis dictum enim. Vestibulum viverra at ex vitae congue. Sed in luctus dolor. Nulla quis turpis ut eros mollis rutrum. Pellentesque eleifend, magna sed placerat aliquam, felis nisl maximus enim, eget gravida odio lorem ac magna. Cras auctor viverra efficitur. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec ac sodales sem. Sed eleifend justo at blandit imperdiet. Cras id augue in arcu euismod posuere.
                \n
                Proin tempor lectus vel tellus pellentesque sagittis. Donec tempus a ante ut feugiat. Aliquam euismod consequat ultrices. Ut ullamcorper enim diam, at egestas mi cursus et. Sed nec lacinia ligula. Donec efficitur leo sed lorem rhoncus, sed mollis magna feugiat. Duis purus elit, interdum nec nulla ut, tempor imperdiet ex. Maecenas eget dignissim erat.
                \n
                Etiam congue, est vel finibus pellentesque, mauris orci interdum felis, eget hendrerit dolor elit eget nisi. Duis orci ex, fermentum eget feugiat in, mattis sit amet quam. Aenean viverra pulvinar lobortis. Mauris vel pellentesque sem. Donec felis tellus, finibus vitae urna et, interdum suscipit lacus. In ut dolor non diam varius faucibus iaculis at ligula. Sed vel tortor euismod, congue lectus et, tincidunt libero. Proin lacinia, eros eget aliquam ornare, tortor elit lobortis diam, et semper lorem nisi non felis. Fusce accumsan at mi vel aliquam. Sed tincidunt aliquam orci eu luctus. Ut lacus velit, efficitur id dolor sit amet, luctus volutpat tortor. Donec nec lectus ultrices, dictum turpis in, vestibulum purus.
                \n
                Sed suscipit, mauris hendrerit eleifend lacinia, quam lacus condimentum sapien, sit amet pulvinar arcu mauris id nunc. Suspendisse vel nisi placerat, ultricies ante ut, feugiat est. Fusce pulvinar in magna sit amet efficitur. Mauris facilisis odio id dolor fringilla, ac tincidunt odio rhoncus. Maecenas mattis semper magna, eget blandit tortor. Mauris vehicula posuere eros, in pharetra massa rutrum in. Nullam dignissim rhoncus felis, in finibus neque egestas ac. Phasellus leo ante, accumsan sit amet nibh et, rutrum sodales neque. Donec ultrices est leo, et tristique felis pharetra nec. Vestibulum malesuada neque vestibulum elit placerat, eget bibendum arcu vehicula. Duis quis eleifend leo. Nam ultricies mollis ipsum at pharetra.
                \n
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla vitae quam odio. Praesent quis ligula dictum, suscipit turpis id, mollis mauris. Nunc quis placerat neque. Donec at metus leo. Fusce eleifend massa in tortor imperdiet volutpat. Aliquam molestie felis turpis, mattis molestie nunc ullamcorper eu. Vivamus augue justo, placerat quis eros lobortis, sollicitudin ornare nisi. Donec viverra cursus mauris a luctus. Duis at quam sed sapien ultricies egestas id non turpis. Pellentesque commodo, lectus ut feugiat congue, libero nunc egestas massa, malesuada posuere felis dolor at massa. Suspendisse vel sollicitudin orci. Aliquam quis porttitor tellus. Praesent posuere, sapien a pretium egestas, elit urna maximus nisl, id suscipit augue lacus vel mauris.`,
                title: "Новость",
                id: "312312sdasd",
                post_date: "1111111",
            }
        ]
    },
    vac: {
        button: "Откликнуться"
    },
    form: {
        title: "Мы рассмотрим Вашу кандидатуру и свяжемся в ближайшее время.",
        name: {
            label: "Как к вам обращаться?",
            isRequired: true,
            placeholder: ""
        },
        email: {
            label: "E-mail",
            isRequired: true,
            placeholder: ""
        },
        tel: {
            label: "Телефон",
            isRequired: false,
            placeholder: "+7 (___) ___-__-__"
        },
        message: {
            isRequired: true,
            labelVacancy: "Сопроводительная записка",
            labelContacts: "Сообщение. Это может быть вопрос или пожелание",
            placeholder: ""
        },
        button: "Отправить",
    },
    success:
        {
            topVac: "Вы успешно откликнулись на вакансию",
            lowerVac: "С Вами свяжутся в ближайшее время",
            topCon: "Отправлено",
            topNo: "Произошла ошибка",
            lowerNo: "Попробуйте позже"
        },
}