import { useState } from "react";
import { data } from "../../data";
import fetchData from "../../fetch";
import './Form.css';
import InputMask from 'react-input-mask';

function Form({url, messageLabel, setSend}) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [message, setMessage] = useState("");
     
    async function buttonClickHandler(e) {
        // валидация
        if (name !== "" && email !== "" && message !== "" && email.indexOf('@') !== -1){
            // отменяем отправку
            e.preventDefault();
            const result = await fetchData('POST', url, {
                name,
                email,
                phone: tel,
                message
            });
            // Проверка запроса
            if (result !== undefined) {
                if ("status" in result) 
                    if (result.status === "Sent successfully") setSend(true);
                    else setSend(-1);
                else setSend(-1);
            }
            else setSend(-1);
        }     
    }

    function changeInput(e, type) {
        switch (type){
            case "name":
                setName(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "tel":
                setTel(e.target.value);
                break;
            default:
                setMessage(e.target.value);
                break;
        }
    }

    return (
        <>
            <form className="Form">
                <div className="Form-container">
                    <div className="Form-column">
                        <div className="input-group">
                            <label>
                                <div className="label-row">
                                {data.form.name.label}
                                {(data.form.name.isRequired) ? <span> *</span> : ""}
                                </div>
                                <div className="label-row">
                                <input
                                    required={data.form.name.isRequired}
                                    placeholder={data.form.name.placeholder}
                                    value={name}
                                    onChange={(e)=>changeInput(e, "name")}
                                />
                                </div>
                            </label>
                            </div>
                            <div className="input-group">
                            <label>
                                <div className="label-row">
                                {data.form.email.label}
                                {(data.form.email.isRequired) ? <span> *</span> : ""}
                                </div>
                                <div className="label-row">
                                <input
                                    placeholder={data.form.email.placeholder}
                                    required={data.form.email.isRequired}
                                    type="email"
                                    value={email}
                                    onChange={(e)=>changeInput(e, "email")}
                                />
                                </div>
                            </label>
                            </div>
                            <div className="input-group">
                            <label>
                                <div className="label-row">
                                {data.form.tel.label}
                                {(data.form.tel.isRequired) ? <span> *</span> : ""}
                                </div>
                                <div className="label-row">
                                <InputMask
                                    placeholder={data.form.tel.placeholder}
                                    required={data.form.tel.isRequired}
                                    mask={"+7 (999) 999-99-99"}
                                    value={tel}
                                    onChange={(e)=>changeInput(e, "tel")}
                                />
                                </div>
                            </label>
                        </div>
                        
                    </div>
                    <div className="Form-column">
                        <label>
                            <div className="label-row">
                                {messageLabel}
                                {(data.form.message.isRequired) ? <span> *</span> : ""}
                            </div>
                            <div className="label-row">
                                <textarea 
                                    required={data.form.message.isRequired} placeholder={data.form.message.placeholder}
                                    value={message}
                                    onChange={(e)=>changeInput(e, "message")}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="Form-footer">
                    <div className="form-hint"><span>*</span> - обязательное поле</div>
                    <div className="form-button"><button onClick={(e)=>buttonClickHandler(e)}>{data.form.button}</button></div>
                </div>
            </form>
        </>
    )
}

export default Form;