import Description from "../../Components/Description/Description";
import List from "./List/List";
import { data } from "../../data";

import './Examinations.css';
import { Helmet } from "react-helmet";

function Examinations() {
    return (
        <section className="Examinations">
            <Helmet>
                <title>{data.pages.exams}</title>
            </Helmet>
            <div className="Description">
                <Description dataStr={
                    [data.exams.description.title, data.exams.description.text]
                }/>
            </div>
            <div className="lists">
                <List/>
            </div>
            <div className="ps">
                <p>{data.exams.ending}</p>
            </div>
        </section>
    )
}

export default Examinations;