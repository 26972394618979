import { useState } from "react";
import { data } from "../../../data";
import List from "./List/List";
import Map from "./Map/Map";

import './Court.css';

function Court() {
    const [showType, setShowType] = useState(false);

    function changeShowType(type) {
        if (type === 0 && showType) setShowType(!showType);
        else if (type === 1 && !showType) setShowType(!showType);
    }

    return (
        <section className="Court-container">
            <div className="title"><h2>{data.courts.title}</h2></div>
            <div className="show-type">
                <span 
                    className={(!showType) ? "active" : ""}
                    onClick={()=>changeShowType(0)}
                >{data.courts.showTypes[0]}</span>
                <span
                    className={(showType) ? "active" : ""}
                    onClick={()=>changeShowType(1)}
                >{data.courts.showTypes[1]}</span>
            </div>
            <div className="courts">
                {(!showType) ? <Map/> : <List/>}
            </div>
        </section>
    )
}

export default Court;