import { data } from '../../data';

import Description from '../../Components/Description/Description';
import Court from './Court/Court';
import Requisites from './Requisites/Requisites';

import './About.css';
import { Helmet } from 'react-helmet';

function About() {
    return (
        <div className="About">
            <Helmet><title>{data.pages.about}</title></Helmet>
            <div className="Description">
                <Description 
                    dataStr={[data.about.description.title,
                                 data.about.description.text]}
                />
            </div>
            <div className='Courts'>
                <Court/>
            </div>
            <div className='Requisites'>
                <Requisites/>
            </div>
        </div>
    )
}

export default About;