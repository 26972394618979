import { useEffect, useState } from "react";
import { data } from "../../data";
import fetchData from "../../fetch";

import Modal from "../../Components/Modal/Modal";
import Form from "../../Components/Form/Form";
import './Vacancies.css';
import Success from "../../Components/Success/Success";
import { Helmet } from "react-helmet";

function Vacancies() {

    const [newsList, setNewsList] = useState([undefined]);

    const [isShowing, closeModal] = useState(false);
    const [isVacSend, setVacSend] = useState(false);
    const [id, setID] = useState("");

    async function createList() {
        const url = `${data.backend.server}${data.backend.path.getVacancies}`;
        const result = await fetchData('GET', url, false);

        if (result !== undefined) setNewsList(result);
        else setNewsList([undefined]);
    }

    function getMonthName(month) {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        return months[month];
    }

    function formatTextWithParagraphs(text) {
        // Разбиваем текст на абзацы по символу '\n'
        const paragraph = text.split('\n');
        // Создаем новый массив, в котором каждый элемент будет обернут в тег <p>
        const paragraphsHTML = paragraph.map((paragraph, i) => <p key={i}>{paragraph}</p>);

        return paragraphsHTML;
    }

    function buttonOnClick(i) {
        setID(i);
        closeModal(true)
    }


    const ModalContent = 
        (isVacSend === false) ? 
            {title:"Чтобы откликнуться на вакансию, заполните форму ниже",
            component: 
            <div className="vac-including">
                <div className="title">{data.form.title}</div>
                {
                    <Form 
                        messageLabel={data.form.message.labelVacancy}
                        url={
                            data.backend.server+
                            data.backend.path.sendResponse+'/'+
                            id
                        }
                        setSend={setVacSend}
                    />
                }
            </div>}
            :
            {
                component: <Success type={(isVacSend === -1) ? "no" : "yesVac"}/> 
            }
            

    useEffect(()=>{       
        createList();
    }, []);
    useEffect(()=>{       
        if (isShowing === false) setVacSend(false);
    }, [isShowing]);

    return (
        <>
            <Modal isShowing={isShowing} including={ModalContent} closeModal={closeModal}/>
            <div className="Vacancies">
                <Helmet><title>{data.pages.vacs}</title></Helmet>
                <div className="title"><h2>{data.news.title}</h2></div>
                <div className="vacs-list">
                    {newsList.map((v, i)=>{
                        if (v === undefined) return "";
                        const date = new Date(v.post_date);
                        // Получаем компоненты даты
                        const day = date.getDate();
                        const month = getMonthName(date.getMonth());
                        const year = date.getFullYear();

                        // Собираем статью
                        const paragraphs = formatTextWithParagraphs(v.content);
                        // Собираем итоговую строку
                        const formattedDate = `${day} ${month}, ${year} год`;

                        return <section className="vac-container" key={v.id}>
                            <div className="vac-header">
                                <div className="title"><h3>{v.title}</h3></div>
                                <div className="date">{formattedDate}</div>
                            </div>
                            <article>
                                <div>{paragraphs}</div>
                            </article>      
                            <div className="vac-button">
                                <button onClick={()=>buttonOnClick(v.id)}>{data.vac.button}</button>
                            </div>         
                        </section>
                    })  }
                </div>
            </div>
        </>
        
    )
}

export default Vacancies;