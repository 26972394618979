import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from './Routes/Root';
import reportWebVitals from './Tests/reportWebVitals';

import './fonts/OpenSans.ttf';
import './fonts/Inter-Regular.ttf';

import Main from './Routes/Main/Main';
import About from './Routes/About/About';
import Examinations from './Routes/Examinations/Examinations';
import News from './Routes/News/News';
import Vacancies from './Routes/Vacancies/Vacancies';
import Contacts from './Routes/Contacts/Contacts';
import NotFound from './Routes/404/404';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Main/>,
      },
      {
        path: "/about",
        element: <About/>,
      },
      {
        path: "/examination",
        element: <Examinations/>,
      },
      {
        path: "/news",
        element: <News/>,
      },
      {
        path: "/vacancies",
        element: <Vacancies/>,
      },
      {
        path: "/contacts",
        element: <Contacts/>,
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
