
import Showcase from "./Showcase/Showcase";
import Partners from "./Partners/Partners";
import { Helmet } from "react-helmet";
import './Main.css';
import { data } from "../../data";

function Main() {
  return (
    <div className="Main">
      <Helmet>
        <title>{data.pages.main}</title>
      </Helmet>
      <Showcase/>
      <Partners/>
    </div>
  );
}

export default Main;
